import React from 'react';
import Main from './components/main';
import Header from './components/header';
import logo from './assets/logo.png';
import GlobalStyle from './styles/global';
import { Footer, FooterItem } from './components/footer';

class App extends React.Component {
  componentDidMount() {
    console.log('huldigt der halben zwiebel 🙏');
  }

  render() {
    return (
      <>
        <GlobalStyle />
        <Header src={logo} />
        <Main />
        <Footer>
          <FooterItem>philosophie.</FooterItem>
          <FooterItem>instagram.</FooterItem>
          <FooterItem>kontakt.</FooterItem>
        </Footer>
      </>
    );
  }
}

export default App;
