import styled, { css } from 'styled-components';
import React from 'react';

export const Footer = styled.footer`
  margin-top: auto;
  display: flex;
  justify-content: space-evenly;
`;

const StyledP = styled.p`
  margin: 0;
  display: block;
  color: #fff;
  padding: 0.2rem;
  ${props =>
    props.rotation &&
    css`
      transform: rotate(${props.rotation}deg);
    `}
`;

const StyledSpan = styled.span`
  display: inline-block;
  ${props =>
    props.rotation &&
    css`
      transform: rotate(${props.rotation}deg);
    `}
`;

export class FooterItem extends React.PureComponent {
  getRandInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  render() {
    let { children: letters } = this.props;
    letters = letters.split('');

    return (
      <StyledP rotation={this.getRandInt(-3, 3)}>
        {letters.map(letter => (
          <StyledSpan rotation={this.getRandInt(-8, 8)}>{letter}</StyledSpan>
        ))}
      </StyledP>
    );
  }
}
