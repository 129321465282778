import React from 'react';
import styled from 'styled-components';
import { fadeIn } from '../styles/keyframes';

const BigImgWrapper = styled.header`
  width: 100%;
  display: flex;

  @media (min-width: 768px) {
    margin-top: 5rem;
  }
`;

const Img = styled.img`
  max-width: 100%;
  max-height: 17rem;
  margin: auto;
  opacity: 0;
  animation: ${fadeIn} 1s 0.5s forwards;
  pointer-events: none;
`;

export default props => (
  <BigImgWrapper>
    <Img {...props} />
  </BigImgWrapper>
);
