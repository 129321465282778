import { createGlobalStyle } from 'styled-components';
import TravelingTypewriter from '../assets/fonts/TravelingTypewriter.ttf';

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'Traveling Typewriter';
  src: url(${TravelingTypewriter});
  font-weight: 400;
  font-style: normal;
}
    

  body {
    margin: 0;
    background-color: #000;
    font-family: 'Traveling Typewriter', monospace;
  }

  div#root {
    max-width: 920px;
  margin: 0 auto;
  padding: 2rem;
  min-height: calc(100vh - 4rem);
  display: flex;
  flex-direction: column;
  }
`;

export default GlobalStyle;
